<app-header *ngIf="!config.workUnderConstruction"></app-header>
<div class="container">
    <div class="logo-container" *ngIf="!config.workUnderConstruction">
        <a routerLink="/home">
            <img src="../../assets/images/logo.png" />
            <h3>Blush Boutique</h3>
        </a>
    </div>
    <app-menu *ngIf="!config.workUnderConstruction"></app-menu>
    <router-outlet></router-outlet>
</div>
<app-footer *ngIf="!config.workUnderConstruction"></app-footer>