<div class="insta-container">
    <div class="insta-header">
        <h3 class="default-heading">Instagram</h3>
    </div>
    <div class="insta-list">
        <section class="insta-product-item">
            <img src="../../assets/images/insta-default.png" alt="insta" />
            <span><i class="fa fa-instagram"></i></span>
        </section>
        <section class="insta-product-item">
            <img src="../../assets/images/insta-default.png" alt="insta" />
            <span><i class="fa fa-instagram"></i></span>
        </section>
        <section class="insta-product-item">
            <img src="../../assets/images/insta-default.png" alt="insta" />
            <span><i class="fa fa-instagram"></i></span>
        </section>
        <section class="insta-product-item">
            <img src="../../assets/images/insta-default.png" alt="insta" />
            <span><i class="fa fa-instagram"></i></span>
        </section>
    </div>
</div>