<div class="collection-container">
    <div class="collection-header">
        <h3 class="default-heading">COLLECTION LIST</h3>
    </div>
    <div class="collection-list">
        <section class="collection-tile-container">
            <img src="../../assets/images/collection-tile-default-gold.png" alt="collection" />
            <figcaption class="feature-overlay">
                <h3>Sarees</h3>
                <div class="feature-detail">
                    <button>Shop</button>
                </div>
            </figcaption>
        </section>
        <section class="collection-tile-container">
            <img src="../../assets/images/collection-tile-default-gold.png" alt="collection" />
            <figcaption class="feature-overlay">
                <h3>Jewelers</h3>
                <div class="feature-detail">
                    <button>Shop</button>
                </div>
            </figcaption>
        </section>
        <section class="collection-tile-container">
            <img src="../../assets/images/collection-tile-default-gold.png" alt="collection" />
            <figcaption class="feature-overlay">
                <h3>Kurthis</h3>
                <div class="feature-detail">
                    <button>Shop</button>
                </div>
            </figcaption>
        </section>
        <section class="collection-tile-container">
            <img src="../../assets/images/collection-tile-default-gold.png" alt="collection" />
            <figcaption class="feature-overlay">
                <h3>Tops</h3>
                <div class="feature-detail">
                    <button>Shop</button>
                </div>
            </figcaption>
        </section>
    </div>
</div>