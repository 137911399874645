import { Component } from '@angular/core';
import { Config } from '../../core/config';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent {

  constructor(public config: Config) {
  }

}
