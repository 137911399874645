<div class="login-container form-container">
    <form id="customer-login" method="post" [formGroup]="loginFormGroup">
        <div class="custom-row">
            <label for="customer-email">Email</label>
            <input id="customer-email" name="email" type="text" formControlName="email" />
        </div>
        
        <div class="custom-row">
            <label for="customer-email">Password</label>
            <small class="right"><em><a routerLink="/account/forgot" routerLinkActive="active">Forgot your password?</a></em></small>
            <input id="customer-email" name="password" type="password" formControlName="password" />
        </div>
        
        <div class="custom-row">
            <button>Login</button>
            <small class="right"><em>New Customer? <a routerLink="/account/create-account" routerLinkActive="active">Sign up -></a></em></small>
        </div>
    </form>
</div>