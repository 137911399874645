<div class="construction-container">
        <div class="const-image-cont">
            <img src="../../assets/images/Logo-2.jpg" />
        </div>
        <div class="const-content-cont">
            <h4>Under Construction</h4>
            <p>We are working hard to make this website available. You will then find our new design and new collection!</p>
            <p>Until then we look forward to seeing you on our Facebook and Instagram page.</p>
            <div class="const-btn-cont">
                <ul (click)="onExternalLinkClicked($event);">
                    <li id="el-1">Instagram</li>
                    <li id="el-2">Facebook</li>
                </ul>
            </div>
        </div>
</div>