import { Component } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent {
  title = 'blush-boutique';
  showSearchbar = false;
  searchText = '';

  getTitle(): string {
    return this.title;
  }

  hambergerMenuClicked(event): void {
    if (!event.target.id || event.target.id === 'header-hamberger-menu') {
      // Menu open block
      document.getElementById('header-hamberger-menu').style.display = "none";
      document.getElementById('header-close').style.display = "block";
      document.getElementById('hamberger-menu-items').style.display = "block";
      this.showSearchbar = false;
    } else {
      // Menu close block
      this.closeMenu();
    }
  }

  closeMenu(): void {
    document.getElementById('header-hamberger-menu').style.display = "block";
    document.getElementById('header-close').style.display = "none";
    document.getElementById('hamberger-menu-items').style.display = "none";
  }

  searchLinkClicked(): void {
    this.showSearchbar = !this.showSearchbar;
    this.searchText = '';
    this.closeMenu();

    if (this.showSearchbar) {
      setTimeout(() => {
        document.getElementById('search-bar').focus();
      }, 0);
    }
  }

  searchEntered(): void {
    this.showSearchbar = false;
  }
}
