<div class="create-account-container form-container">
    <form id="customer-create-account" method="post">
        <div class="custom-row">
            <label for="customer-first-name">First Name</label>
            <input id="ustomer-first-name" type="text" />
        </div>
        
        <div class="custom-row">
            <label for="customer-last-name">Last Name</label>
            <input id="customer-last-name" type="text" />
        </div>

        <div class="custom-row">
            <label for="customer-email">Email</label>
            <input id="customer-email" type="text" />
        </div>

        <div class="custom-row">
            <label for="customer-password">Password</label>
            <input id="customer-password" type="text" />
        </div>
        
        <div class="custom-row">
            <button>Sign Up</button>
            <small class="right"><em>Returning Customer? <a routerLink="/account/login" routerLinkActive="active">Login -></a></em></small>
        </div>
    </form>
</div>