import { Injectable } from '@angular/core';
import { Config } from '../core/config';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  constructor(public router: Router, private config: Config, private location: Location) {}

  /**
   * which handles user authorization
   */
  canActivate() {
      console.log(this.router.url);
      if (this.config.workUnderConstruction) {
        this.gotoUnderConstructionPage();
        return true;
      } 

      return true;
  }

  gotoUnderConstructionPage() {
      this.router.navigate(['under-construction']);
  }

  gotoHomePage() {
    this.router.navigate(['home']);
  }
}
