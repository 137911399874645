<div class="home-container">
    <app-carousel></app-carousel>
    <app-new-products></app-new-products>
    <div class="featured-text"> 
        <h3 class="default-heading">WOMEN'S CLOTHING BOUTIQUE</h3>
        <p>At Blush Boutique, we pride ourselves on curating high-quality, well-made clothing. As part of our search for quality pieces to stock our women’s clothing boutique, we’ve made it our mission to provide stunning pieces that run true to size and are built to last.</p>
        <p>We’re constantly refreshing our offerings to make sure we always have the latest trends available to you. We have new, exciting items in stock every day, and they go fast! With high-quality materials, consistent sizing, and a constant flow of new products, Blush Boutique is the best place to find clothes that represent real women.</p>
    </div>
    <app-collection-tile></app-collection-tile>
    <app-insta-tile></app-insta-tile>
</div>