import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor() {}

  ngOnInit() {
    const myLatlng = new google.maps.LatLng(11.4416, 77.6945);
    const mapOptions = {
      zoom: 16,
      center: myLatlng
    }
    const map = new google.maps.Map(document.getElementById("map_canvas"), mapOptions);

    const marker = new google.maps.Marker({
        position: myLatlng,
        title: "Blush Boutique",
        label: {text: "Blush Boutique", color: "#000", fontWeight: "bold"}
    });

    // To add the marker to the map, call setMap();
    marker.setMap(map);
  }
}
