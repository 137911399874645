import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '@pages/home/home.component';
import { ConstructionComponent } from '@pages/construction/construction.component';
import { AccountComponent } from '@pages/account/account.component';
import { ContactUsComponent } from '@widgets/contact-us/contact-us.component';

import { AuthGuardService } from 'src/services/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuardService] },
  { path: 'under-construction', component: ConstructionComponent },
  { path: 'account/:page', component: AccountComponent, canActivate: [AuthGuardService] },
  { path: 'contact-us', component: ContactUsComponent, canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
