<div class="carousel-container">
    <carousel>
        <slide>
        <img src="assets/images/nature/4.jpg" alt="first slide" style="display: block; width: 100%;">
        <div class="carousel-caption d-none d-md-block">
            <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </div>
        </slide>
        <slide>
        <img src="assets/images/nature/5.jpg" alt="second slide" style="display: block; width: 100%;">
        <div class="carousel-caption d-none d-md-block">
            <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </div>
        </slide>
        <slide>
        <img src="assets/images/nature/6.jpg" alt="third slide" style="display: block; width: 100%;">
        <div class="carousel-caption d-none d-md-block">
            <h3>Third slide label</h3>
            <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </div>
        </slide>
    </carousel>
</div>