<header>
    <div class="nav-header">
        <div class="header-left">
            <section class="hamberger-menu-icon" (click)="hambergerMenuClicked($event)">
                <span id="header-hamberger-menu"><i class="fa fa-bars fa-2x"></i></span>
                <span id="header-close">x</span>
            </section>
        </div>
        <div class="header-title">
        </div>
        <div class="header-right">
            <ul>
                <li>
                    <a routerLink="/account/login" >
                        <i class="fa fa-user fa-1x" aria-hidden="true"></i>
                        <span> Login/Create Account</span>
                    </a>
                </li>
                <li>
                    <i class="fa fa-shopping-bag fa-1x" aria-hidden="true"></i>
                    <span> My Cart</span>
                </li>
                <li (click)="searchLinkClicked();">
                    <i class="fa fa-search fa-1x" aria-hidden="true"></i>
                    <span> Search</span>
                </li>
            </ul>
        </div>
    </div>
</header>

<div id="hamberger-menu-items" class="hamberger-menu-items">
    <ul (click)="closeMenu();">
        <li>
            <a routerLink="/home">Home</a>
        </li>
        <li>
            <a routerLink="/account/login">Login/Create Account</a>
        </li>
        <li>My Chart</li>
        <li>New</li>
        <li>Tops</li>
        <li>Dresses</li>
        <li>Bottoms</li>
        <li>Sarees</li>
        <li>Jwellery</li>
        <li>Blowses</li>
        <li>Accesories</li>
    </ul>
</div>

<div id="header-searchbar" class="header-searchbar" *ngIf="showSearchbar">
    <i class="fa fa-search fa-1x" aria-hidden="true"></i>
    <input id="search-bar" type="text" [(ngModel)]="searchText"  (keyup.enter)="searchEntered();"/>
</div>