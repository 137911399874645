<div class="menu-container">
    <ul (click)="onMenuClick($event);">
        <li>New</li>
        <li>Tops</li>
        <li>Dresses</li>
        <li>Bottoms</li>
        <li>Sarees</li>
        <li>Jwellery</li>
        <li>Blowses</li>
        <li>Accessories</li>
    </ul>
</div>