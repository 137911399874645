import { Component } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  onMenuClick(event): void {
    console.log('in');
    const elements = event.currentTarget.children;
    for (let element of elements) {
      element.className = "";
    }
    event.target.className = "active"
  }
}
