import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// External plugin modules
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { GoogleMapsModule } from '@angular/google-maps';

import { ShellComponent } from '@pages/shell/shell.component';
import { HomeComponent } from '@pages/home/home.component';
import { AccountComponent } from '@pages/account/account.component';
import { ConstructionComponent } from '@pages/construction/construction.component';

// Widgets
import { HeaderComponent } from '@widgets/header/header.component';
import { LoginComponent } from '@widgets/login/login.component';
import { CreateAccountComponent } from '@widgets/create-account/create-account.component';
import { ForgotComponent } from '@widgets/forgot/forgot.component';
import { MenuComponent } from '@widgets/menu/menu.component';
import { CarouselComponent } from '@widgets/carousel/carousel.component';
import { NewProductsComponent } from '@widgets/new-products/new-products.component';
import { CollectionTileComponent } from '@widgets/collection-tile/collection-tile.component';
import { InstaTileComponent } from '@widgets/insta-tile/insta-tile.component';
import { ProductTileComponent } from '@widgets/product-tile/product-tile.component';
import { ContactUsComponent } from '@widgets/contact-us/contact-us.component';
import { FooterComponent } from '@widgets/footer/footer.component';

@NgModule({
  declarations: [
    ShellComponent,
    HomeComponent,
    AccountComponent,
    ConstructionComponent,
    HeaderComponent,
    LoginComponent,
    CreateAccountComponent,
    ForgotComponent,
    MenuComponent,
    CarouselComponent,
    NewProductsComponent,
    CollectionTileComponent,
    InstaTileComponent,
    ProductTileComponent,
    ContactUsComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    GoogleMapsModule,
    CarouselModule.forRoot()
  ],
  exports: [
  ],
  providers: [],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [ShellComponent]
})
export class AppModule { }
