import { Component } from '@angular/core';
import { Config } from '../../core/config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-construction',
  templateUrl: './construction.component.html',
  styleUrls: ['./construction.component.scss']
})
export class ConstructionComponent {

  constructor(private config: Config, private router: Router) {
    if(!this.config.workUnderConstruction) {
      this.router.navigate(['home']);
    }
  }

  onExternalLinkClicked(event): void {
    if (event.target.id === 'el-1') {
      window.open('https://www.instagram.com/blush_boutique_collection', '_blank');
    } else {
      window.open('https://www.facebook.com/blush_boutique_collection-107852324030239', '_blank');
    }
  }
}
