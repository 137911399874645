<div class="new-products-container">
    <div class="new-products-header">
        <h3 class="default-heading">What's New</h3>
    </div>
    <div class="new-products-list">
        <section class="new-products-header">
            <p>Try our amazing new collections</p>
        </section>
        <section class="product-tile-container">
            <app-product-tile></app-product-tile>
            <app-product-tile></app-product-tile>
            <app-product-tile></app-product-tile>
        </section>
    </div>
</div>