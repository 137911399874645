import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent {

  routeParams: any;
  pageName: string;

  constructor(private route: ActivatedRoute) {
    this.routeParams = this.route.params.subscribe(params => {
      this.pageName = params['page'];
    });

    console.log('PageName - ', this.pageName);
  }

  ngOnDestroy() {
    this.routeParams.unsubscribe();
  }
}
