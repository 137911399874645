<div class="forgot-container form-container">
    <form id="forgot-form" method="post">
        <div class="custom-row">
            <label for="customer-email">Email</label>
            <input id="customer-email" name="email" type="text" />
        </div>
        <div class="custom-row">
            <p><em>We will send you an email to reset your password.</em></p>
        </div>
        
        <div class="custom-row">
            <button>Submit</button>
            <button (click)="onCancelClicked();">Cancel</button>
        </div>
    </form>
</div>