import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  forgotFormGroup: FormGroup;

  constructor(private router: Router) {
  }

  ngOnInit(){
  }

  onCancelClicked(): void {
    this.router.navigate(['account/login']);
  }
}
