<footer class="container">
    <div class="footer-line"></div>
    <section class="footer-container">
        <div class="footer-left">
            <span>Copyright &copy; 2020 Blush Boutique </span>
        </div>
        <div class="footer-right">
            <span>Follow us on - </span>
            <span><a href="https://www.instagram.com/blush_boutique_collection/" target="_blank"><i class="fa fa-instagram fa-2x"></i></a></span>
            <span><a href="https://www.facebook.com/blush_boutique_collection-107852324030239" target="_blank"><i class="fa fa-facebook-f fa-2x"></i></a></span>
        </div>
    </section>
</footer>