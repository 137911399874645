<div class="contact-us-container form-container">
    <h3 class="default-heading">CONTACT US</h3>
    <p>Thank you for contacting us.  Here at Blush Boutique we love to hear your questions, concerns and feedback.  Our support team will be in contact as soon as possible. 9am-5pm Monday-Saturday (IST).</p>
    <div class="customer-contact-us">
        <div class="left">
            <div class="contact-details">
                <p>
                    <strong>Office Address - </strong><br />
                    Vasavi complex, 134D, Salem Main Rd, Nadaraja Nagar, Komarapalayam, Tamil Nadu 638183, India.
                </p>
                <p>
                    <strong>Phone - </strong><br />
                    +91-9500396992, +91-9344669049
                </p>
                <p>
                    <strong>Email - </strong><br />
                    blushboutique.kmp@gmail.com
                </p>
            </div>

            <form id="customer-contact-us col-6" method="post">
                <div class="custom-row">
                    <label for="customer-name">Name</label>
                    <input id="ustomer-first-name" type="text" />
                </div>
    
                <div class="custom-row">
                    <label for="customer-email">Email</label>
                    <input id="customer-email" type="text" />
                </div>
    
                <div class="custom-row">
                    <label for="customer-password">Message</label>
                    <textarea id="customer-message"></textarea>
                </div>
                
                <div class="custom-row">
                    <button>Send</button>
                </div>
            </form>
        </div>

        <div class="right" id="map_canvas">
            <!-- <google-map
                height="500px"
                width="100%"
                [zoom]="zoom"
                [center]="center"
                [options]="options"
            ></google-map> -->
        </div>
    </div>
</div>